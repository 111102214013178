import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Button, PopUp, Icon, SelectNewVersion } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";
import styles from "./styles.module.scss";
import locals from "../../../helpers/locals";

@inject("newTimesheetStore", "appStore", "sessionStore")
@observer
class DayDetailsPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      toBeDeleted: [],
      format: "on-site",
      hours: 0,
      minutes: 0,
      theme: "N/A"
    };
    this.dataPreparation = this.dataPreparation.bind(this);
  }

  dataPreparation = () => {
    const { newTimesheetStore } = this.props;
    const { themes, themeSelected } = newTimesheetStore;

    return themes.map(theme => ({
      text: theme.name,
      value: theme.name,
      selected: theme.name === themeSelected,
      icon: { type: "dot", color: theme.color }
    }));
  };

  onSelectThemeFunc = opt => {
    const { newTimesheetStore } = this.props;
    const { changeThemeSelected } = newTimesheetStore;
    changeThemeSelected(opt.value);
  };

  validate = () => {
    const { message } = this.state;
    const { newTimesheetStore } = this.props;
    const {
      openClosePopUps,
      addDescriptionToPeriod,
      rangeDetails
    } = newTimesheetStore;
    addDescriptionToPeriod(rangeDetails, message, rangeDetails.themeName);
    openClosePopUps("dayDetails");
  };

  toHoursAndMinutes = totalMinutes => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${this.padToTwoDigits(hours)}h${this.padToTwoDigits(minutes)}m`;
  };

  padToTwoDigits = num => num.toString().padStart(2, "0");

  deleteThemePerDay = item => {
    const { newTimesheetStore } = this.props;
    const { deleteTimeRange } = newTimesheetStore;
    deleteTimeRange([item]);
    /* const { toBeDeleted } = this.state;
    const newItems = [...toBeDeleted];
    newItems.push(item);
    this.setState({ toBeDeleted: newItems });*/
  };

  render() {
    const {
      newTimesheetStore,
      device,
      appStore,
      sessionStore,
      i18n
    } = this.props;
    const {
      openClosePopUps,
      dayDetails,
      timesheet,
      deleteTimeRange,
      warnings,
      editable,
      addPeriod,
      deleteDay,
      changeThemeSelected,
      themeSelected
    } = newTimesheetStore;
    const { account } = sessionStore;
    const { currentLanguage } = appStore;
    const { toBeDeleted, format, hours, minutes, theme, period } = this.state;

    let dayDetailsToDisplay = dayDetails.filter(
      item1 =>
        !toBeDeleted.some(
          item2 =>
            ((item1.normal &&
              item1.normal.theme &&
              item2.themeName === item1.normal.theme.name) ||
              (item1.extra &&
                item1.extra.theme &&
                item2.themeName === item1.extra.theme.name)) &&
            item2.day === item1.day
        )
    );
    const warningLabel = {
      MAX_TIME_PER_DAY: i18n._(t`MAX_TIME_PER_DAY`),
      MAX_NORMAL_TIME_PER_DAY: i18n._(t`MAX_NORMAL_TIME_PER_DAY`),
      MAX_SPECIAL_TIME_PER_DAY: i18n._(t`MAX_SPECIAL_TIME_PER_DAY`),
      MAX_THEMES_PER_DAY: i18n._(t`MAX_THEMES_PER_DAY`),
      ABNORMAL_TIME_PER_DAY: i18n._(t`ABNORMAL_TIME_PER_DAY`)
    };
    const warningsToShow =
      dayDetailsToDisplay && dayDetailsToDisplay[0]
        ? warnings.filter(e => e.day == dayDetailsToDisplay[0].day)
        : [];

    const addFullDay = () => {
      let newDate = new Date(
        timesheet.year,
        timesheet.month - 1,
        dayDetails[0]?.day
      );
      addPeriod({
        start: newDate,
        end: newDate,
        hours: 8,
        minutes: 0,
        themeName: themeSelected || theme,
        type: "normal",
        place: format
      });
      changeThemeSelected("N/A");
    };

    const themesList = this.dataPreparation();

    return (
      <div>
        <PopUp
          mobile={device === "mobile"}
          onClose={() => {
            openClosePopUps("dayDetails");
            this.setState({ toBeDeleted: [] });
          }}
        >
          <div
            style={{
              position: "relative",
              height: "400px",
              width: device === "mobile" ? "100%" : "380px"
            }}
          >
            <div
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "16px",
                color: "#232a3e",
                marginBottom: "15px"
              }}
            >
              {dayDetails &&
                dayDetails.length &&
                ("0" + dayDetails[0].day).slice(-2)}{" "}
              {locals[currentLanguage].months.long[
                timesheet.month - 1
              ].toLowerCase()}{" "}
              {timesheet.year}
            </div>
            <div className="popup-details">
              {warningsToShow && warningsToShow.length > 0 && (
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginBottom: "15px"
                  }}
                >
                  {warningsToShow.map(warning => {
                    return (
                      <p
                        style={{
                          color: warning.critical ? "#a01125" : "#f1ba52"
                        }}
                      >
                        {warning.warning !== "MAX_THEMES_PER_DAY" ? (
                          `${warningLabel[warning.warning]} (${Math.floor(
                            warning.number / 60
                          )}h${warning.number % 60}m)`
                        ) : (
                          <>
                            {`${warningLabel[warning.warning]} (${
                              warning.number
                            })`}
                          </>
                        )}
                      </p>
                    );
                  })}
                </div>
              )}
              {dayDetailsToDisplay &&
              dayDetailsToDisplay[0] &&
              (dayDetailsToDisplay[0]?.extra ||
                dayDetailsToDisplay[0]?.normal) ? (
                <>
                  <div
                    style={{
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "16px",
                      color: "#232a3e",
                      marginBottom: "15px"
                    }}
                  >
                    <Trans>Plages d'heures sélectionnées :</Trans>
                  </div>
                  <table style={{ width: "100%" }}>
                    {dayDetailsToDisplay &&
                      dayDetailsToDisplay.length &&
                      dayDetailsToDisplay.map(ele => {
                        return (
                          <>
                            {ele && ele.normal && (
                              <tr
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "14px",
                                  height: "30px"
                                }}
                              >
                                <td>
                                  <span style={{ marginRight: "10px" }}>
                                    <Icon
                                      type="menu-burger"
                                      width={14}
                                      color={ele.normal.theme.color}
                                      color2={ele.normal.theme.color}
                                      filled
                                    />
                                  </span>
                                  <span
                                    title={ele.normal.theme.name}
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "inline-block",
                                      width: "120px",
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {ele.normal.theme.name}
                                  </span>
                                </td>
                                <td style={{ "font-weight": "bold" }}>
                                  normal
                                </td>
                                <td>
                                  {this.toHoursAndMinutes(
                                    ele.normal.hours * 60 + ele.normal.minutes
                                  )}
                                </td>
                                {account.role === "CANDIDATE" &&
                                editable("CANDIDATE") ? (
                                  <td>
                                    <span
                                      onClick={() => {
                                        this.deleteThemePerDay({
                                          themeName: ele.normal.theme.name,
                                          day: ele.normal.day
                                        });
                                      }}
                                      style={{
                                        float: "right",
                                        marginLeft: "10px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <Icon
                                        type="delete"
                                        width={16}
                                        color="#8d0417"
                                        color2="#d3354a"
                                        filled
                                      />
                                    </span>
                                    {/* // todo  */}
                                    {/*  <span style={{float: "right"}}>*/}
                                    {/*  <Icon*/}
                                    {/*    type="edit"*/}
                                    {/*    width={16}*/}
                                    {/*    color="#8d0417"*/}
                                    {/*    color2="#d3354a"*/}
                                    {/*    filled*/}
                                    {/*  />*/}
                                    {/*</span>*/}
                                  </td>
                                ) : null}
                              </tr>
                            )}
                            {ele && ele.extra && (
                              <tr
                                style={{
                                  fontFamily: "Montserrat",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "#232a3e",
                                  height: "30px"
                                }}
                              >
                                <td>
                                  <span style={{ marginRight: "10px" }}>
                                    <Icon
                                      type="menu-burger"
                                      width={14}
                                      color={ele.extra.theme.color}
                                      color2={ele.extra.theme.color}
                                      filled
                                    />
                                  </span>
                                  <span
                                    title={ele.extra.theme.name}
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "inline-block",
                                      width: "120px",
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {ele.extra.theme.name}
                                  </span>
                                </td>

                                <td
                                  style={{
                                    "font-weight": "bold",
                                    color: "#bf142a"
                                  }}
                                >
                                  extra
                                </td>

                                <td style={{ color: "#bf142a" }}>
                                  {this.toHoursAndMinutes(
                                    ele.extra.hours * 60 + ele.extra.minutes
                                  )}
                                </td>
                                {account.role === "CANDIDATE" &&
                                editable("CANDIDATE") ? (
                                  <td>
                                    <span
                                      onClick={() => {
                                        this.deleteThemePerDay({
                                          themeName: ele.extra.theme.name,
                                          day: ele.extra.day
                                        });
                                      }}
                                      style={{
                                        float: "right",
                                        marginLeft: "10px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <Icon
                                        type="delete"
                                        width={16}
                                        color="#8d0417"
                                        color2="#d3354a"
                                        filled
                                      />
                                    </span>
                                    {/* // todo  */}
                                    {/*  <span style={{float: "right"}}>*/}
                                    {/*  <Icon*/}
                                    {/*    type="edit"*/}
                                    {/*    width={16}*/}
                                    {/*    color="#8d0417"*/}
                                    {/*    color2="#d3354a"*/}
                                    {/*    filled*/}
                                    {/*  />*/}
                                    {/*</span>*/}
                                  </td>
                                ) : null}
                              </tr>
                            )}
                          </>
                        );
                      })}
                  </table>
                </>
              ) : account.role === "CANDIDATE" &&
                ["open", "rejected"].includes(timesheet.status) ? (
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#232a3e",
                    marginBottom: "15px"
                  }}
                >
                  <Trans>Il n’y a pas de temps enregistré ce jour.</Trans>
                  <div style={{ marginTop: "20px" }}>
                    <Trans>
                      Ajoutez 1 jour complet (8h présentielles pour ce jour) en
                      utilisant le bouton ci-dessous
                    </Trans>
                  </div>
                  <div className="selectThemes">
                    <label className="timesheet-form-label" htmlFor="theme">
                      <Trans>Projets</Trans>
                    </label>
                    <div style={{ width: "60%" }}>
                      <SelectNewVersion
                        key={themeSelected || theme}
                        options={{ groupA: themesList }}
                        placeholder={i18n._(t`Sélectionner`)}
                        onSelect={this.onSelectThemeFunc}
                        valid={true}
                        disabled={false}
                      />
                    </div>
                  </div>

                  <Button
                    style={{
                      marginTop: "20px",
                      marginLeft: "20%",
                      width: "64%"
                    }}
                    onClick={() => {
                      deleteDay(dayDetails[0].day);
                      this.setState({ toBeDeleted: [] });
                      addFullDay();
                      openClosePopUps("dayDetails", true);
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div>
                        <Icon
                          type="plus-circle"
                          color="#ffffff"
                          color2="#ffffff"
                          width={17}
                          height={17}
                        />
                      </div>{" "}
                      <div style={{ marginLeft: "7px" }}>
                        <Trans>Ajouter 1 jour complet </Trans>
                      </div>
                    </div>
                  </Button>
                  <div style={{ marginTop: "20px" }}>
                    <Trans>
                      Utilisez l’espace “Ajouter des heures” au-dessus du
                      calendrier pour ajouter d’autres types d’heures{" "}
                    </Trans>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#232a3e",
                    marginBottom: "15px"
                  }}
                >
                  <Trans>Il n’y a pas de temps enregistré ce jour.</Trans>
                </div>
              )}
            </div>

            {account.role === "CANDIDATE" && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  display: "flex"
                }}
              >
                {device === "mobile" &&
                  ["open", "rejected"].includes(timesheet.status) && (
                    <div
                      style={{
                        float: "left",
                        padding: "24px 20px 20px 0"
                      }}
                    >
                      <span
                        style={{
                          paddingTop: "20px",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          textDecoration: "underline",
                          cursor: "pointer",
                          fontWeight: "normal",
                          color: "#8f8f8f",
                          marginTop: "20px"
                        }}
                        onClick={() => {
                          openClosePopUps("dayDetails");
                          openClosePopUps("addTimeManually");
                        }}
                      >
                        <Trans>Ajouter une nouvelle plage</Trans>
                      </span>
                    </div>
                  )}
                <div
                  style={{
                    float: "right",
                    padding: "20px 0"
                  }}
                >
                  <Button
                    variant="primary"
                    size="small"
                    onClick={() => {
                      openClosePopUps("dayDetails");
                    }}
                  >
                    <Trans>Confirmer</Trans>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </PopUp>
      </div>
    );
  }
}

DayDetailsPopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    deleteTimeRange: PropTypes.func,
    rangeDetails: PropTypes.shape({}),
    warnings: PropTypes.shape({}),
    timesheet: PropTypes.shape({}),
    dayDetails: PropTypes.shape({})
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  device: PropTypes.string,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};
export default withApollo(withI18n()(withRouter(DayDetailsPopUp)));
